import type { CoralogixRum } from "@coralogix/browser/";

import { isDev } from "../utils/env.ts";
import { getUserSource } from "../utils/source-extracting.ts";
import { useEffect } from "react";
import { version } from "../../public/app-version.json";

const userSource = getUserSource();

export interface SafeRum {
  info: (typeof CoralogixRum)["info"];
  error: (typeof CoralogixRum)["error"];
  warn: (typeof CoralogixRum)["warn"];
  debug: (typeof CoralogixRum)["debug"];
  critical: (typeof CoralogixRum)["critical"];
  setUserContext: (typeof CoralogixRum)["setUserContext"];
}
let Rum: SafeRum = {
  info: (..._args: unknown[]) => undefined,
  error: (..._args: unknown[]) => undefined,
  warn: (..._args: unknown[]) => undefined,
  debug: (..._args: unknown[]) => undefined,
  critical: (..._args: unknown[]) => undefined,
  setUserContext: (..._args: unknown[]) => undefined,
};
let initialized = false;
export async function initializeRum() {
  if (typeof window !== "undefined" && !initialized) {
    initialized = true;
    const { CoralogixRum } = await import("@coralogix/browser");
    const { UrlType } = await import("@coralogix/browser/src/types");
    if (!isDev() && window.location.hostname.includes("hiburim.io")) {
      CoralogixRum.init({
        public_key: "cxtp_F0r2W35yewHZeb3y0Fm0qEmWVrgmsy",
        application: "hiburim",
        version: version,
        coralogixDomain: "EU2",
        ignoreUrls: [
          new RegExp(".*/browser/.*(logs|sessionrecording).*"),
          new RegExp(".*googlesyndication.com.*"),
        ],
        labelProviders: [
          {
            urlType: UrlType.PAGE,
            providerFunc: () => userSource ?? {},
          },
        ],
        sessionRecordingConfig: {
          enable: true,
          autoStartSessionRecording: true,
          recordConsoleEvents: true,
        },
      });
    }

    const bindSafe = <T extends keyof SafeRum>(key: T) => {
      return (CoralogixRum[key]?.bind(CoralogixRum) ||
        ((..._args: unknown[]) => undefined)) as SafeRum[T];
    };
    Rum = {
      info: bindSafe("info"),
      error: bindSafe("error"),
      warn: bindSafe("warn"),
      debug: bindSafe("debug"),
      critical: bindSafe("critical"),
      setUserContext: bindSafe("setUserContext"),
    };
  }
}

export const useCoralogix = () => {
  useEffect(() => {
    initializeRum();
  }, []);
  if (initialized) {
    return Rum;
  }
  return {
    info: (..._args: unknown[]) => undefined,
    error: (..._args: unknown[]) => undefined,
    warn: (..._args: unknown[]) => undefined,
    debug: (..._args: unknown[]) => undefined,
    critical: (..._args: unknown[]) => undefined,
    setUserContext: (..._args: unknown[]) => undefined,
  };
};

export const getCoralogix = () => {
  return Rum;
};
