import Image from "antd/lib/image";
import Modal from "antd/lib/modal";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { MdAddToHomeScreen } from "react-icons/md";
import useDisclosure from "../../utils/useDisclosure";
import { MenuAction } from "./MenuAction";

const PWAInstallationInstruction: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // this is shit, iphone uses the title and it, as far as i've seen not customizeable
  document.title = "חיבורים";
  return (
    <>
      <MenuAction
        label="הוסף את האתר למסך הבית"
        icon={<MdAddToHomeScreen size="32px" />}
        onClick={onOpen}
      />
      <Modal
        open={isOpen}
        onOk={onClose}
        okText="סגור"
        onCancel={onClose}
        cancelButtonProps={{ style: { display: "none" } }}
        styles={{
          content: {
            direction: "rtl",
          },
        }}
        title="הוספה למסך הבית"
      >
        <Typography.Paragraph>
          על מנת לקבל התראות עבור המשחק באייפון, ראשית יש להוסיף את המשחק למסך
          הבית.
        </Typography.Paragraph>
        <Typography.Paragraph>
          עקבו אחר ההוראות, חזרו לאתר, ולאחר מכן הקליקו על הפעמון שיופיע בתחתית
          המסך.
        </Typography.Paragraph>
        <Space align="center" style={{ width: "100%" }}>
          <Image
            alt="לחץ שתף, ולאחר מכן הוסף לדף הבית"
            src="pwa-installation-ios.gif"
            style={{ border: "1px solid", width: "100%" }}
          />
        </Space>
      </Modal>
    </>
  );
};

export default PWAInstallationInstruction;
