"use client";
import { AntdRegistry } from "@ant-design/nextjs-registry";
import { type PropsWithChildren } from "react";
import ThemeConfigProvider from "../providers/ThemeConfigProvider";

export const RootStyleRegistry = ({ children }: PropsWithChildren) => {
  return (
    <AntdRegistry>
      <ThemeConfigProvider>{children}</ThemeConfigProvider>
    </AntdRegistry>
  );
};
