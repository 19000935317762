import { User } from "@firebase/auth";
import { Guess, MAXIMUM_MISTAKES, State } from "../models/gameModels.ts";
import { getCoralogix } from "../services/coralogix.ts";
import { isDev } from "../utils/env.ts";
import { isAdmin, UserData } from "./firebase.ts";
import { getTimer } from "./timer.ts";
import { reportScore } from "./user-scores.ts";

export function reportLoseEvent(state: State, auth?: UserData) {
  if (!isAdmin(auth) && !isDev()) {
    const timer = getTimer();
    gtag("event", "lose", {
      incomplete_remaining: state.incomplete.length,
    });
    fbq("trackCustom", "lose", {
      incomplete_remaining: state.incomplete.length,
    });
    getCoralogix().info("lose", {
      incomplete_remaining: state.incomplete.length,
      dt: state.initialPuzzle.dt,
      categories: state.complete.map((c) => c.name).join(",") || "none",
      time: timer?.seconds,
    });
    reportScore(state.initialPuzzle.dt, false, state.mistakesRemaining, [
      ...state.complete.map((c) => c.difficulty),
    ]);
  }
}

export function reportWinEvent(state: State, auth?: UserData) {
  if (!isAdmin(auth) && !isDev()) {
    const timer = getTimer();
    fbq("trackCustom", "win", {
      mistakes: MAXIMUM_MISTAKES - state.mistakesRemaining,
    });
    gtag("event", "win", {
      mistakes: MAXIMUM_MISTAKES - state.mistakesRemaining,
    });
    getCoralogix().info("win", {
      mistakes: MAXIMUM_MISTAKES - state.mistakesRemaining,
      dt: state.initialPuzzle.dt,
      categories: state.complete.map((c) => c.name).join(","),
      time: timer?.seconds,
    });
    reportScore(state.initialPuzzle.dt, true, state.mistakesRemaining, [
      ...state.complete.map((c) => c.difficulty),
    ]);
  }
}

export function reportGuessEvent(state: State, guess: Guess) {
  if (!isDev()) {
    const timer = getTimer();
    fbq("trackCustom", "guess", {
      type: guess.correct ? "correct" : "incorrect",
    });
    gtag("event", "guess", {
      type: guess.correct ? "correct" : "incorrect",
    });
    getCoralogix().info("guess", {
      type: guess.correct ? "correct" : "incorrect",
      guess: guess.words,
      dt: state.initialPuzzle.dt,
      guessKey: guess.words
        .map((w) => w.word)
        .sort()
        .join(","),
      category: guess.category,
      oneWayCategory: guess.one_way_category,
      order: state.complete.length + 1,
      time: timer?.seconds,
    });
  }
}

export function reportShareEvent(socialName: string, shouldShareTime: boolean) {
  if (!isDev()) {
    gtag("event", "share", {
      type: socialName,
      shareTime: shouldShareTime,
    });
    fbq("trackCustom", "Share", {
      medium: socialName,
      shareTime: shouldShareTime,
    });
    getCoralogix().info("share", {
      medium: socialName,
      shareTime: shouldShareTime,
    });
  }
}
export function reportAdViewEvent(placementInfo: any) {
  if (!isDev()) {
    gtag("event", "ad-shown", placementInfo);
    getCoralogix().info("ad-shown", placementInfo);
  }
}

export function reportSignInEvent(user: User) {
  if (!isDev()) {
    gtag("event", "sign_up");
    fbq("track", "signIn");
    getCoralogix().info("signIn", {
      userId: user.uid,
      userName: user.displayName,
      userEmail: user.email,
    });
  }
}
