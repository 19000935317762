"use client";

import { useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import InstructionsModal from "./InstructionsModal";
import { MenuAction } from "./MenuAction";

const InstructionsAction = () => {
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  return (
    <>
      <InstructionsModal
        promptedOpen={instructionsOpen}
        closePrompted={() => setInstructionsOpen(false)}
      />
      <MenuAction
        label="הוראות"
        icon={
          <IoIosInformationCircleOutline
            size="32px"
            onClick={() => setInstructionsOpen(true)}
          />
        }
      />
    </>
  );
};

export default InstructionsAction;
