"use client";

import { useRouter } from "next/navigation";
import { IoHome } from "react-icons/io5";
import { MenuAction } from "./MenuAction";

const HomePageButton = () => {
  const router = useRouter();
  return (
    <MenuAction
      label="עמוד ראשי"
      icon={<IoHome size="32px" />}
      onClick={() => router.push("/")}
    />
  );
};

export default HomePageButton;
