"use client";

import Col from "antd/lib/col";
import Space from "antd/lib/space";
import Paragraph from "antd/lib/typography/Paragraph";
import { isMobile } from "../../utils/is-mobile";
import { Accessibility } from "./Accessibility";
import ReportError from "./ReportError";
import { Row } from "antd/lib/grid";
import Link from "next/link";
import Typography from "antd/lib/typography";
import Divider from "antd/lib/divider";
import { sizes } from "../../styles/sizes";
import BuyMeACoffee from "../BuyMeACoffee.tsx";

const easyNavigationLinks = {
  "פאזל יומי": "/",
  הוראות: "/instructions",
  אודות: "/about",
  "הבלוג שלנו": "/blog",
  "הצהרת פרטיות": "/privacy",
  "הסטוריית המשחקים שלי": "/history",
};

const Footer = () => {
  return (
    <>
      <Col style={{ textAlign: "center", direction: "rtl" }}>
        <Paragraph>
          <b>חיבורים</b> הוא משחק חידות, בו בכל יום מופיעה חידה חדשה.
          <br />
          המשחק הוא גרסה בעברית של המשחק המצליח&nbsp;
          <a
            href="https://www.nytimes.com/games/connections"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connections
          </a>{" "}
          מבית ה-New York Times.
        </Paragraph>
        <div>
          <Space align="center" style={{ justifyContent: "center" }}>
            <ReportError />
            <BuyMeACoffee />
          </Space>
        </div>
        <Divider />
        <Col
          style={{
            maxWidth: sizes.viewWidth,
            margin: "auto",
          }}
        >
          <Typography.Title level={5}>ניווט פשוט</Typography.Title>
          <Row
            style={{
              textAlign: "justify",
              textAlignLast: "center",
            }}
            wrap
          >
            {Object.entries(easyNavigationLinks).map(([title, link], i) => (
              <Col span={12} key={i}>
                <Link key={title} href={link}>
                  <Typography.Link>{title}</Typography.Link>
                </Link>
              </Col>
            ))}
            <Col span={12}>
              <Accessibility />
            </Col>
          </Row>
        </Col>
        {isMobile() && (
          <div
            style={{
              height: "28px",
            }}
          >
            &nbsp;
          </div>
        )}
      </Col>
    </>
  );
};

export default Footer;
