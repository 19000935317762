import { useCallback, useEffect } from "react";

const rawSizes = {
  completedRowWidth: ["100%", "100%", "100%"],
  completedRowHeight: ["60vh", "240px", "300px"],
  wordWidth: ["22vw", "100px", "130px"],
  wordHeight: ["10vh", "40px", "90px"],
  viewWidth: ["90vw", "544px", "544px"],
  mistakesRemaining: ["40%", "50%", "50%"],
  modalWidth: ["85vw", "600px", "600px"],
  menuWidth: ["100%", "max(50vw, 600px)", "max(50vw, 600px)"],
};

export const rawSizesForNext = Object.fromEntries(
  Object.entries(rawSizes).map(([key, value]) => [
    key,
    `(max-width: 600px) ${value[0]}, (max-width: 1024px) ${value[1]}, (min-width: 1024px) ${value[2]}`,
  ]),
) as Record<keyof typeof rawSizes, string>;

export const useSizeListener = () => {
  if (typeof window === "undefined") {
    return { evaluateSize: () => {} };
  }
  const evaluateSize = useCallback(() => {
    const breakpoints = [
      "(max-width: 600px)", // mobile
      "(max-width: 1024px)", // tablet
      "(min-width: 1024px)", // desktop
    ];
    const size = breakpoints.findIndex(
      (query) => window.matchMedia(query).matches,
    );
    Object.entries(rawSizes).forEach(([key, value]) => {
      document.documentElement.style.setProperty(
        `--hiburim-s-${key}`,
        value[size],
      );
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", evaluateSize);
    return () => window.removeEventListener("resize", evaluateSize);
  }, [typeof window, evaluateSize]);
  evaluateSize();
  return { evaluateSize };
};

export const sizes = Object.fromEntries(
  Object.keys(rawSizes).map((key) => [key, `var(--hiburim-s-${key})`]),
) as Record<keyof typeof rawSizes, string>;
// init with default size for ssr
Object.entries(rawSizes).forEach(([key, value]) => {
  globalThis?.document?.documentElement.style.setProperty(
    `--hiburim-s-${key}`,
    value[0],
  );
});
