import { signInWithCustomToken } from "@firebase/auth";
import type { InputRef } from "antd/lib";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Input from "antd/lib/input";
import Menu from "antd/lib/menu";
import Modal from "antd/lib/modal";
import Typography from "antd/lib/typography";

import { useCallback, useRef, useState } from "react";
import { auth, getImpersonateToken, useAuth } from "../../services/firebase";

export function AdminFeatures() {
  const [modalOpen, setModalOpen] = useState(false);
  const user = useAuth();
  const impersonateUserRef = useRef<InputRef>(null);
  const impersonateUser = useCallback((uid: string) => {
    console.log(`Impersonating user ${uid}`);
    user?.user
      ?.getIdToken(false)
      .then((token) => getImpersonateToken(token, uid))
      .then((token) => {
        signInWithCustomToken(auth, token as unknown as string).then(() => {
          //
        });
      });
  }, []);
  return (
    <Col>
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{ style: { display: "none " } }}
      >
        <Typography.Title level={4}>Impersonate User</Typography.Title>
        <Typography.Text>
          Enter the UID of the user you want to impersonate
        </Typography.Text>
        <Input ref={impersonateUserRef} type="text" />
        <Button
          type="primary"
          onClick={() =>
            impersonateUser(impersonateUserRef.current?.input?.value!!)
          }
        >
          Impersonate
        </Button>
      </Modal>
      <Menu
        items={[
          {
            label: "Admin",
            key: "Admin",
            type: "group",
            children: [
              {
                key: "impersonate",
                label: "Impersonate User",
                onClick: () => setModalOpen(true),
              },
            ],
          },
        ]}
        mode="inline"
        style={{
          width: "100%",
          direction: "rtl",
          border: "none",
        }}
      />
    </Col>
  );
}
