import Button from "antd/lib/button";

const BuyMeACoffee = () => (
  <Button
    size={"large"}
    type="primary"
    style={{ background: "#fbd400", color: "black" }}
    icon={"☕️"}
    aria-label={"לתמיכה"}
    onClick={() => window.open("https://buymeacoffee.com/hiburim", "_blank")}
  >
    <b>לתמיכה</b>
  </Button>
);

export default BuyMeACoffee;
