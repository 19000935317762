"use client";

import Icon from "@ant-design/icons";
import Avatar from "antd/lib/avatar";
import Drawer from "antd/lib/drawer";
import Menu from "antd/lib/menu";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FaBook, FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosHome } from "react-icons/io";
import { MdOutlineHistory, MdQuestionMark } from "react-icons/md";
import { isAdmin, useAuth } from "../../services/firebase";
import { MenuAction } from "./MenuAction";
import guest from "../../../public/guest.webp";
import { AdminFeatures } from "./AdminFeatures";
import { HiOutlineSquares2X2 } from "react-icons/hi2";

const { Text } = Typography;

export const NavigationMenu = () => {
  const router = useRouter();
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuObject = [
    {
      label: "ניווט",
      key: "ניווט",
      type: "group",
      children: [
        {
          key: "עמוד ראשי",
          icon: <Icon component={IoIosHome} />,
          label: "עמוד ראשי",
        },
        {
          key: "יצירת פאזל",
          icon: <Icon component={HiOutlineSquares2X2} />,
          label: "יצירת פאזל",
        },
        {
          key: "אודות",
          icon: <Icon component={BsInfoCircle} />,
          label: "אודות",
        },
        {
          key: "איך לשחק?",
          icon: <Icon component={MdQuestionMark} />,
          label: "איך לשחק?",
        },
        {
          key: "הבלוג",
          icon: <Icon component={FaBook} />,
          label: "הבלוג",
        },
      ],
    },
    {
      label: "משתמש",
      key: "משתמש",
      type: "group",
      children: [
        {
          key: "תוצאות קודמות",
          icon: <Icon component={MdOutlineHistory} />,
          label: "תוצאות קודמות",
        },
        {
          key: auth !== undefined ? "התנתקות" : "התחברות",
          icon:
            auth !== undefined ? (
              <Icon component={FaSignOutAlt} />
            ) : (
              <Icon component={FaSignInAlt} />
            ),
          label: auth !== undefined ? "התנתקות" : "התחברות",
        },
      ],
    },
  ];
  const labelToPathMapping = {
    "תוצאות קודמות": "/history",
    התנתקות: "/logout",
    התחברות: "/login",
    "עמוד ראשי": "/",
    "יצירת פאזל": "/custom",
    אודות: "/about",
    "איך לשחק?": "/instructions",
    הבלוג: "/blog",
  };
  const currentLabel = Object.entries(labelToPathMapping).find(
    ([_, path]) => path === globalThis?.window?.location?.pathname ?? "/",
  )?.[0];
  const userName = auth?.user?.displayName ?? "אורח";
  return (
    <>
      <MenuAction
        label="תפריט"
        icon={<GiHamburgerMenu size="32px" />}
        onClick={() => setIsOpen(true)}
      />
      <Drawer
        closable={false}
        title={
          <Space
            style={{ width: "100%" }}
            direction="vertical"
            align="center"
            aria-label={userName}
          >
            <Avatar src={auth?.user?.photoURL ?? guest.src} size={55} />
            <Text>{userName}</Text>
          </Space>
        }
        styles={{
          body: {
            padding: 0,
          },
          header: {
            border: "none",
          },
        }}
        placement="right"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        width={200}
      >
        <Menu
          items={menuObject}
          mode="inline"
          selectedKeys={[currentLabel ?? "עמוד ראשי"]}
          style={{
            width: "100%",
            direction: "rtl",
            border: "none",
          }}
          onClick={({ key }) => {
            router.push(
              labelToPathMapping[key as keyof typeof labelToPathMapping],
            );
            setIsOpen(false);
          }}
        />
        {isAdmin(auth) && <AdminFeatures />}
      </Drawer>
    </>
  );
};
