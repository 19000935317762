"use client";

import Image from "antd/lib/image";
import Icon from "@ant-design/icons";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import { FaSquareFull } from "react-icons/fa";
import logo from "../../public/logo.webp";

const { Text } = Typography;

export const InstructionsContent = () => (
  <Col style={{ width: "100%" }}>
    <Typography.Paragraph>
      <Text strong>מצא קבוצות של ארבע מילים בעלות נושא משותף</Text>
      <ul>
        <li>
          <Text>בחר ארבע מילים, ולחץ "שלח" כדי לבדוק את התשובה</Text>
        </li>
        <li>
          <Text>מצא את כל ארבע הקבוצות מבלי לעשות 4 טעויות</Text>
        </li>
      </ul>
    </Typography.Paragraph>
    <Typography.Paragraph>
      <Text strong>דוגמאות:</Text>
      <ul>
        <li>
          <Text aria-label={"קטגוריה: פירות, מילים: תפוח, בננה, אגס, ענבים."}>
            <div aria-hidden={true}>
              <b>פירות:</b>&nbsp; תפוח, בננה, אגס, ענבים
            </div>
          </Text>
        </li>
        <li>
          <Text aria-label={"קטגוריה: מרק, מילים: עוף, כתום, עדשים, חרירה."}>
            <div aria-hidden={true}>
              <b>מרק:</b>&nbsp; עוף, כתום, עדשים, חרירה
            </div>
          </Text>
        </li>
      </ul>
    </Typography.Paragraph>
    <Typography.Paragraph>
      הנושא המשותף ספציפי יותר מ-"שמות", "פעלים", או "מילים בעלות 5 אותיות". בכל
      יום החידה משתנה, וקיים פתרון אחד בלבד.
    </Typography.Paragraph>
    <Text>
      לכל קבוצה קיים צבע, אותו תראו כאשר תפתרו את הקבוצה. רמת הקושי משתנה בהתאם
      לצבע הקבוצה:
      <Col
        aria-label={
          "צבעי הקבוצות בסדר קושי עולה הם: צהוב - קל, ירוק - בינוני, כחול - קשה, סגול - קשה מאוד."
        }
      >
        <div aria-hidden={true}>
          <Row>
            <Icon
              component={FaSquareFull}
              style={{ color: "#fbd400", margin: "5px" }}
            />{" "}
            פשוט
          </Row>
          <Row>
            <Icon
              component={FaSquareFull}
              style={{ color: "#b5e352", margin: "5px" }}
            />
            &nbsp;
          </Row>
          <Row>
            <Icon
              component={FaSquareFull}
              style={{ color: "#729eeb", margin: "5px" }}
            />
            &nbsp;
          </Row>
          <Row>
            <Icon
              component={FaSquareFull}
              style={{ color: "#bc70c4", margin: "5px" }}
            />{" "}
            מסובך
          </Row>
        </div>
      </Col>
    </Text>
    <Col span={14} offset={5}>
      <Image
        preview={false}
        alt="חיבורים"
        src={logo.src}
        style={{ width: "100%", height: "100%" }}
      />
    </Col>
    <Text>
      בהצלחה!
      <a href="mailto:nimrodavni7@gmail.com">
        {" "}
        נשמח לשמוע פידבקים והצעות לשיפור
      </a>
    </Text>
  </Col>
);
