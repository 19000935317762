"use client";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import Typography from "antd/lib/typography";
import { InstructionsContent } from "../Instructions";
import { useLocalStorageValue } from "../../utils/useLocalStorageFlag";

const InstructionsModal = ({
  promptedOpen,
  closePrompted,
}: {
  promptedOpen: boolean;
  closePrompted: () => void;
}) => {
  const [isAcknoledged, setAck] = useLocalStorageValue(
    "instructions",
    false,
    Boolean,
  );
  const closeCallback = () => {
    setAck(true);
    closePrompted();
  };
  return (
    <Modal
      open={!isAcknoledged || promptedOpen}
      onCancel={closeCallback}
      onOk={closeCallback}
      okText="הבנתי, בוא נשחק!"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { direction: "rtl" } }}
    >
      <Col dir="rtl" style={{ width: "100%" }}>
        <Typography.Title level={3}>איך לשחק חיבורים</Typography.Title>
        <InstructionsContent />
      </Col>
    </Modal>
  );
};

export default InstructionsModal;
