const getQueryParams = (url: string): { [key: string]: string } => {
  const params: { [key: string]: string } = {};
  new URL(url).searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};
const identifySource = (referrer: string): string => {
  if (!referrer) return "Direct";
  const referrerHostname = new URL(referrer).hostname.toLowerCase();
  const searchEngines: { [key: string]: string } = {
    "google.com": "Google Search",
    "bing.com": "Bing Search",
    "yahoo.com": "Yahoo Search",
    "duckduckgo.com": "DuckDuckGo Search",
  };
  const socialMedia: { [key: string]: string } = {
    "facebook.com": "Facebook",
    "tiktok.com": "TikTok",
    "twitter.com": "Twitter",
    "linkedin.com": "LinkedIn",
    "instagram.com": "Instagram",
    "reddit.com": "Reddit",
  };
  for (const domain in searchEngines) {
    if (referrerHostname.includes(domain)) return searchEngines[domain];
  }
  for (const domain in socialMedia) {
    if (referrerHostname.includes(domain)) return socialMedia[domain];
  }

  return referrerHostname;
};

export const getUserSource = () => {
  if (typeof document === "undefined") return;
  let log = {
    source: "Unknown",
    medium: "Unknown",
    campaign: "Unknown",
    content: "Unknown",
    term: "Unknown",
    composedSourceValue: "Unknown",
    type: "Unknown",
    referrer: document.referrer ? document.referrer : "None",
  };
  log.source = identifySource(document.referrer);
  const params = getQueryParams(window.location.href);
  if (params.utm_source) log.source = params.utm_source;
  if (params.utm_medium) log.medium = params.utm_medium;
  if (params.utm_campaign) log.campaign = params.utm_campaign;
  if (params.utm_content) log.content = params.utm_content;
  if (params.utm_term) log.term = params.utm_term;

  if (params.fbclid) {
    log.campaign = `Facebook Campaign`;
  }

  if (log.campaign !== "Unknown") {
    log.composedSourceValue = `${log.source} / ${log.campaign}`;
    log.type = "Advertising";
  } else if (log.source !== "Unknown") {
    log.composedSourceValue = `${log.source}`;
    log.type = "Organic";
  }
  return log;
};
