"use client";
import { onAuthStateChanged } from "@firebase/auth";
import { doc } from "@firebase/firestore/lite";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useCoralogix } from "../services/coralogix.ts";
import {
  AuthContext,
  AuthContextType,
  DbUser,
  Role,
  UserData,
  auth,
  db,
  getDoc,
  getUserId,
  setDoc,
} from "../services/firebase.ts";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  if (!globalThis.window) return <>{children}</>;
  const [data, setData] = useState<UserData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const Rum = useCoralogix();
  const checkAuth = useCallback(() => {
    return onAuthStateChanged(auth, async (user) => {
      if (user === undefined || user === null) {
        const userId = getUserId(Rum);
        Rum.setUserContext({
          user_id: userId,
          user_name: userId,
        });
        setLoading(false);
        setData(undefined);
        return;
      }
      try {
        const userDoc = doc(db, "users", user.uid);
        const dbUser = await getDoc(userDoc);
        if (!dbUser.exists()) {
          // need to create user
          const userId = getUserId(Rum);
          const roles: Role[] = ["viewer"];
          await setDoc(userDoc, { userId, roles }, {});
          setData({
            user,
            userId,
            roles,
          });
          setLoading(false);
        } else {
          // If user exists
          const { userId, roles, state } = dbUser.data() as DbUser; // Assuming DbUser type is defined elsewhere
          setData({
            user,
            userId,
            roles,
            state,
          });
          setLoading(false);
        }
        Rum.setUserContext({
          user_id: user.uid,
          user_name: user.displayName ?? user.email ?? user.uid,
          user_email: user.email ?? user.uid,
        });
      } catch (error) {
        Rum.error("failed getting user roles from firebase", error);
        const userId = getUserId(Rum);
        Rum.setUserContext({
          user_id: userId,
          user_name: userId,
        });
        setLoading(false);
        setData(undefined);
        return;
      }
    });
  }, [Rum]);

  useEffect(() => {
    return checkAuth();
  }, [Rum]);

  const contextValue: AuthContextType = {
    user: data,
    loading,
    recheckAuthentication: checkAuth,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
