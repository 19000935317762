export type Category = {
  difficulty: 1 | 2 | 3 | 4;
  name: string;
  words: string[];
};
export type Puzzle = {
  categories: Category[];
  words: string[];
  dt: string;
};
export type Guess = {
  category: string | null;
  one_way_category: string | null;
  timestamp: Date;
  words: Word[];
  correct: boolean;
};

export type Word = {
  word: string;
  category: string;
  difficulty: 1 | 2 | 3 | 4;
};

export type AnimationState =
  | "NONE"
  | "GUESS-STARTED"
  | "FINISHED"
  | "MISTAKE-STARTED"
  | "LOSE-ANIMATION"
  | "WIN"
  | "FINISH-LOSE"
  | "GAME-FINISHED-WIN"
  | "GAME-FINISHED-LOSE";

export type State = {
  foundGroup: Category | undefined;
  animationInProgress: AnimationState;
  complete: Category[];
  incomplete: Category[];
  initialPuzzle: Puzzle;
  items: string[];
  activeItems: string[];
  transitioningItems: string[];
  mistakesRemaining: number;
  guesses: Guess[];
  result: undefined | "win" | "lose";
  dt: number;
  // @ts-ignore
  gridRef: Ref;
  lastGuessWasOneAway?: Boolean;
  offline?: boolean;
  completeTime?: number;
};

export const MAXIMUM_MISTAKES = 4;

export const difficultyName = {
  1: "קל",
  2: "בינוני",
  3: "קשה",
  4: "קשה מאוד",
};

export const difficultyNameFemale = {
  1: "קלה",
  2: "בינונית",
  3: "קשה",
  4: "קשה מאוד",
};
