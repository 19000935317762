"use client";

import { Col, Row } from "antd/lib/grid";
import List from "antd/lib/list";
import Modal from "antd/lib/modal";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";
import { useState } from "react";
import { BsDashCircle, BsShift } from "react-icons/bs";
import {
  MdKeyboardBackspace,
  MdKeyboardCommandKey,
  MdKeyboardControlKey,
  MdKeyboardReturn,
} from "react-icons/md";

export const Accessibility = () => {
  const [isOpen, setAccessibilityOpen] = useState(false);
  const closeHandler = () => setAccessibilityOpen(false);
  return (
    <>
      <Typography.Link onClick={() => setAccessibilityOpen(true)} tabIndex={0}>
        הצהרת נגישות
      </Typography.Link>
      <Modal
        title="הצהרת נגישות"
        open={isOpen}
        onCancel={closeHandler}
        onOk={closeHandler}
        okText="סגור"
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ direction: "rtl" }}
        styles={{
          content: {
            background: "bg",
            border: "1px solid",
            boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Col
          dir="rtl"
          style={{ width: "100%", padding: 16, textAlign: "justify" }}
        >
          <Typography.Paragraph>
            צוות <b>חיבורים</b> שם דגש רב על נגישות האתר. צוות המשחק משקיע מאמץ
            וזמן רב על הנגשת המשחק לכלל הקהלים. <br />
            בדף זה אפרט על הכלים והשיטות בהן השתמשתי להנגשת האתר. <br />
            במידה וגיליתם תקלת נגישות, אנא דווחו למייל למטה. <br />
            תאריך עדכון אחרון של הצהרת הנגישות: 16.02.2024. <br />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Title level={4}>האתר הונגש באופן הבא:</Typography.Title>
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={[
                "התאמת האתר לכל סוגי הדפדפנים המודרניים.",
                "התאמת האתר לכל סוגי הפלטפורמות – מובייל, טבלטים ודסקטופים.",
                "ערכות צבעים שונות הכוללות מצב ניגודיות (״חשוך״) וערכות צבעים נוספות.",
                "התאמת האתר מבחינת שימוש במקלדת בלבד.",
                "התאמת האתר מבחינת שימוש בקוראי מסך.",
              ]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<BsDashCircle />}
                    description={item}
                  />
                </List.Item>
              )}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Title level={4}>
              על מנת לשחק בשימוש במקלדת בלבד:
            </Typography.Title>
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={[
                "לחצו על מקשי האותיות כדי לבחור את המילה הרצויה.",
                "מסגרת תופיע סביב המילה המתאימה. לחצו Enter כדי לאשר את בחירתכם.",
                "לאחר בחירת ארבעת המילים, לחצו CTRL + Enter (או לחילופין, Command + Enter במחשבי Mac) כדי לשלוח את הפתרון.",
              ]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<BsDashCircle />}
                    description={item}
                  />
                </List.Item>
              )}
            />
          </Typography.Paragraph>
        </Col>
        <Table
          size="small"
          pagination={false}
          bordered
          dataSource={[
            { key: "1", action: "בחירת מילה", keybind: <MdKeyboardReturn /> },
            {
              key: "2",
              action: "שליחת פתרון",
              keybind: (
                <>
                  <MdKeyboardCommandKey />/<MdKeyboardControlKey /> +{" "}
                  <MdKeyboardReturn />
                </>
              ),
            },
            {
              key: "3",
              action: "ניקוי בחירה",
              keybind: (
                <>
                  <MdKeyboardCommandKey />/<MdKeyboardControlKey /> +{" "}
                  <MdKeyboardBackspace />
                </>
              ),
            },
            {
              key: "4",
              action: "ערבול מילים",
              keybind: (
                <>
                  <BsShift /> + <MdKeyboardReturn />
                </>
              ),
            },
            // Add more rows as needed
          ].map((row) => ({
            ...row,
            keybind: (
              <Row style={{ fontFamily: "monospace" }}>{row.keybind}</Row>
            ),
          }))}
          columns={[
            { title: "מקש", dataIndex: "keybind", key: "keybind" },
            { title: "פעולה", dataIndex: "action", key: "action" },
          ]}
        />
      </Modal>
    </>
  );
};
