"use client";

import Layout, { Content, Footer } from "antd/lib/layout/layout";
import { ReactNode, useEffect } from "react";
import DOMListeners from "../components/DOMListeners";
import Header from "../components/Header";
import FooterContent from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import { AuthProvider } from "../providers/AuthProvider";
import { reportAdViewEvent } from "../services/analytics";
import { RootStyleRegistry } from "../styles/AntdStyleRegistry";
import { sizes } from "../styles/sizes";
import { useLocalStorageValue } from "../utils/useLocalStorageFlag";

interface CustomWindow extends Window {
  isWebview?: boolean;
}

const BasePage = ({ children }: { children: ReactNode }) => {
  const [shownAd, setShownAd] = useLocalStorageValue("last-ad-time", 0);
  useEffect(() => {
    if (Date.now() - shownAd > 1000 * 60 * 5) {
      (window as any)?.adBreak({
        type: "preroll", // The type of this placement
        name: "preroll-ad",
        //'afterAd', 'beforeReward', 'adDismissed', 'adViewed'                      // A descriptive name for this placement
        beforeAd: () => {}, // Prepare for the ad. Mute and pause the game flow
        adBreakDone: (placementInfo: any) => {
          reportAdViewEvent(placementInfo);
          setShownAd(Date.now());
        }, // Always called (if provided) even if an ad didn't show
      });

      setShownAd(Date.now());
    }
  }, []);
  return (
    <>
      <AuthProvider>
        <DOMListeners />
        <RootStyleRegistry>
          <Layout
            style={{
              minHeight: "100vh",
              direction: "rtl",
              backgroundColor: "var(--hiburim-bg)",
              color: "var(--hiburim-color)",
            }}
          >
            <Content
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Navbar />
              <Header />
              <Content
                style={{
                  width: sizes.viewWidth,
                }}
              >
                {children}
              </Content>
            </Content>
            {!(globalThis?.window as CustomWindow)?.isWebview ? (
              <Footer
                style={{
                  width: "100%",
                  textAlign: "justify",
                  backgroundColor: "var(--hiburim-menu)",
                  // color: 'var(--hiburim-color)',
                }}
              >
                <FooterContent />
              </Footer>
            ) : (
              <> </>
            )}
          </Layout>
        </RootStyleRegistry>
      </AuthProvider>
    </>
  );
};

export default BasePage;
