"use client";

import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import { useCoralogix } from "../../services/coralogix.ts";
import {
  getOrRegisterServiceWorker,
  subscribeNotificationToken,
  unsubscribeNotificationToken,
} from "../../services/notifications.ts";
import { colors } from "../../styles/themes.tsx";
import useDisclosure from "../../utils/useDisclosure.ts";
import { useLocalStorageValue } from "../../utils/useLocalStorageFlag.tsx";
import {
  WebPushStatus,
  useWebPushStatus,
} from "../../utils/useNotification.tsx";
import { MenuAction } from "./MenuAction.tsx";
import PWAInstallationInstruction from "./PWAInstallationInstruction.tsx";

// Adjust the component usage and properties according to Ant Design components as needed
// Note: This conversion assumes direct mapping of some components and might require additional adjustments or wrappers for full compatibility.
export const WebPushAction = () => {
  const Rum = useCoralogix();
  const TIME_DIFF_ASK_AGAIN = 1000 * 60 * 60 * 24 * 7; // 1 week
  const { status: pushStatus, checkStatus } = useWebPushStatus();
  const [loadingChange, setLoadingChange] = useState(false);
  const [lastShownPopover, setPopoverTs] = useLocalStorageValue<number>(
    "notifications-hint-popover",
    0,
    (v) => parseInt(v as string),
  );
  const shouldShowPopover =
    new Date().getTime() - lastShownPopover >= TIME_DIFF_ASK_AGAIN &&
    pushStatus === WebPushStatus.NOT_SUBSCRIBED;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const subscribeCallback = () => {
    setLoadingChange(true);
    getOrRegisterServiceWorker()
      .then(subscribeNotificationToken)
      .then(() => {
        // Assuming gtag is a function related to Google Analytics that you're using
        // You might need to adjust or remove this line if not applicable
        gtag("event", "webpush-sub");
        Rum.info("webpush-sub");
        checkStatus();
        setLoadingChange(false);
      })
      .catch((e) => {
        Rum.error("failed registering to notifications", e);
        setLoadingChange(false);
      });
  };

  const unsubscribeCallback = () => {
    setLoadingChange(true);
    unsubscribeNotificationToken().then(() => {
      // Assuming gtag is a function related to Google Analytics that you're using
      // You might need to adjust or remove this line if not applicable
      gtag("event", "webpush-unsub");
      Rum.info("webpush-unsub");
      checkStatus();
      setLoadingChange(false);
    });
  };

  if ([WebPushStatus.ERROR, WebPushStatus.UNKNOWN].includes(pushStatus))
    return <></>;
  if (pushStatus === WebPushStatus.REQUIRES_PWA_INSTALLATION)
    return <PWAInstallationInstruction />;
  if (loadingChange)
    return (
      <Spin
        style={{
          margin: "auto",
          color: colors.menuText,
        }}
      />
    );

  return (
    <>
      <Modal
        title="הסרת הרשמה"
        open={isOpen}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            לא משנה
          </Button>,
          <Button
            key="submit"
            type="default"
            onClick={() => {
              unsubscribeCallback();
              onClose();
            }}
          >
            ביטול הרשמה
          </Button>,
        ]}
      >
        <p>להסיר הרשמה להתראות?</p>
      </Modal>
      <Popover
        content={<p>רוצים לקבל תזכורת על פאזל חדש?</p>}
        title="התראות"
        trigger="click"
        open={shouldShowPopover}
        onOpenChange={() => setPopoverTs(new Date().getTime())}
      >
        <Row>
          <MenuAction
            label="התראות"
            icon={
              <IoNotifications
                style={{
                  color:
                    pushStatus === WebPushStatus.SUBSCIBED
                      ? "green"
                      : "default",
                }}
                size="32px"
              />
            }
            onClick={
              pushStatus === WebPushStatus.NOT_SUBSCRIBED
                ? subscribeCallback
                : onOpen
            }
          />
        </Row>
      </Popover>
    </>
  );
};
