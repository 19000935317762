"use client";

import { useEffect } from "react";
import { useCoralogix } from "../services/coralogix";
import { themes } from "../styles/themes";
import { useLocalStorageValue } from "./useLocalStorageFlag";

export const useSetColorTheme = () => {
  const Rum = useCoralogix();
  if (typeof window === "undefined") {
    return () => {};
  }
  const [colorSetName, setColorSetName] = useLocalStorageValue(
    "theme2",
    "בהיר",
  );
  localStorage.setItem("theme2", colorSetName);
  Rum.info("rendering theme provider", { themeName: colorSetName });
  const colorsObject = themes[colorSetName as keyof typeof themes].colors;
  useEffect(() => {
    Object.entries(colorsObject).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--hiburim-${key}`, value);
    });
  }, [colorSetName]);
  return setColorSetName;
};

export const setColorThemeForTests = (themeName: string) => {
  const colorsObject = themes[themeName as keyof typeof themes].colors;
  Object.entries(colorsObject).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--hiburim-${key}`, value);
  });
};
