import { collection, deleteDoc, doc } from "@firebase/firestore/lite";
import {
  Messaging,
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { app, db, setDoc } from "./firebase";

let _messaging: undefined | Messaging = undefined;
const safeGetMessaging = () => {
  if (_messaging) return _messaging;
  _messaging = getMessaging(app);
  return _messaging;
};

const PUSH_SCOPE = "/firebase-push-notification-scope";
const VAPID_PUBLIC_KEY =
  "BL89q5tmrmWrIOpmoJ8lmLnJT6X1Ibc6H1e_WFe5p4UX3B7yr84lsK3GpvmIJyQjduo7xqxBOUxA5sYn5ArI_u0";

export const getOrRegisterServiceWorker = async () => {
  if (!("serviceWorker" in navigator) || navigator.serviceWorker === undefined)
    throw new Error("Service worker not supported");
  // Request permission from user to send notifications
  try {
    const registration =
      await window.navigator.serviceWorker.getRegistration(PUSH_SCOPE);
    if (registration) return registration;
    const newWorkerRegistration = await window.navigator.serviceWorker.register(
      "/firebase-messaging-sw.js",
      { scope: PUSH_SCOPE },
    );
    await navigator.serviceWorker.ready;
    return newWorkerRegistration;
  } catch (e) {
    throw e;
  }
};

const writeTokenToFirebase = async (token: string) => {
  // write token to firebase collection
  const tokenDoc = doc(collection(db, "webpush-subscriptions"), token);
  await setDoc(tokenDoc, { token }, {});
};

const deleteTokenFromFirebase = async (token: string) => {
  const tokenDoc = doc(collection(db, "webpush-subscriptions"), token);
  await deleteDoc(tokenDoc);
};

export const subscribeNotificationToken = async (
  serviceWorkerRegistration: ServiceWorkerRegistration,
) => {
  const token = await getToken(safeGetMessaging(), {
    vapidKey: VAPID_PUBLIC_KEY,
    serviceWorkerRegistration,
  });
  await writeTokenToFirebase(token);
  return token;
};

export const unsubscribeNotificationToken = async () => {
  const token = await getToken(safeGetMessaging());
  await deleteTokenFromFirebase(token);
  if (!(await deleteToken(safeGetMessaging()))) {
    throw new Error("Failed to delete token");
  }
  await getOrRegisterServiceWorker().then(
    async (registration: ServiceWorkerRegistration) => {
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
      }
    },
  );
};

export const onForegroundMessage = () =>
  new Promise((resolve) =>
    onMessage(safeGetMessaging(), (payload) => resolve(payload)),
  );
