"use client";

import { useEffect } from "react";
import { useSizeListener } from "../styles/sizes";

const DOMListeners = () => {
  const { evaluateSize } = useSizeListener();
  useEffect(() => {
    evaluateSize();
    // This hack is intended so the component will render *only* on the client side
  }, []);
  return "";
};

export default DOMListeners;
