"use client";
import { initializeApp } from "@firebase/app";
import { GoogleAuthProvider, User, getAuth } from "@firebase/auth";
import {
  QueryConstraint,
  getDoc as _getDoc,
  getDocs as _getDocs,
  setDoc as _setDoc,
  collection,
  getFirestore,
  query,
} from "@firebase/firestore/lite";
import { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import firebaseConfig from "../firebase.config.ts";
import { Puzzle } from "../models/gameModels.ts";
import { UserState } from "./state.ts";
import { SafeRum } from "./coralogix.ts";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);
export const provider = new GoogleAuthProvider();

export type Role = "admin" | "viewer" | "member";

export interface DbUser {
  userId: string;
  roles: Role[];
  state?: UserState;
}

export interface UserData {
  user: User;
  roles?: Role[];
  userId?: string;
  state?: UserState;
}

type RateLimitData = {
  epoch: number;
  count: number;
};

let RATE_LIMITER: RateLimitData = {
  count: 0,
  epoch: new Date().getTime(),
};

const RATE_INTERVAL_MS = 60 * 1000; // 1 minute
const RATE_LIMIT_COUNT = 100;

function rateLimit() {
  const now = new Date().getTime();
  if (now - RATE_LIMITER.epoch > RATE_INTERVAL_MS) {
    RATE_LIMITER.epoch = now;
    RATE_LIMITER.count = 0;
    return;
  }
  if (RATE_LIMITER.count >= RATE_LIMIT_COUNT) {
    throw new Error("Rate Limited");
  }
  RATE_LIMITER.count += 1;
  return;
}

const USER_ID_LOCAL_STORAGE_KEY = "user-id";

export function getUserId(Rum?: SafeRum): string {
  let userId = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY);
  if (!userId) {
    userId = uuidv4();
    Rum?.info("NEW_USER", { userId });
    localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, userId);
  }
  return userId;
}

export function setDoc(...params: Parameters<typeof _setDoc>) {
  rateLimit();
  return _setDoc(...params);
}

export function getDoc(...params: Parameters<typeof _getDoc>) {
  rateLimit();
  return _getDoc(...params);
}

export function getDocs(...params: Parameters<typeof _getDocs>) {
  rateLimit();
  return _getDocs(...params);
}

export function isAdmin(user?: UserData) {
  return !!user?.roles?.includes("admin");
}

export type AuthContextType = {
  user: UserData | undefined;
  loading: boolean;
  recheckAuthentication: () => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export function useFullAuthenticationObject() {
  if (!globalThis.window) return { user: undefined, loading: false };
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function useAuth() {
  return useFullAuthenticationObject().user;
}

export function useAuthExtended() {
  const { user, loading } = useFullAuthenticationObject();
  return [user, loading] as const;
}

const fetchPuzzle = async (): Promise<Puzzle> => {
  return await fetch("https://daily-puzzle.omrikurtz5.workers.dev/").then(
    (res) => res.json(),
  );
};

export function useFetchDailyPuzzle() {
  const [data, setData] = useState<Puzzle | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    (async () => {
      const request = fetchPuzzle();
      setLoading(true);
      try {
        setData(await request);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return [data, loading, error] as const;
}

export function useDocumentDataLite<Type>(docRef?: any) {
  const [data, setData] = useState<Type | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!docRef) {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          setData(docSnapshot.data() as Type);
        } else {
          setData(undefined);
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return [data, loading, error] as const;
}

export async function queryFirestore<T>(
  collectionPath: string,
  components?: QueryConstraint[],
): Promise<T[]> {
  const db = getFirestore(app);
  const q = query(collection(db, collectionPath), ...(components ?? []));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data() as T);
}

export function useConditionally<T>(
  condition: boolean,
  callback: () => T | Promise<T>,
) {
  const [data, setData] = useState<T | undefined>(undefined);
  useEffect(() => {
    if (condition) {
      const value = callback();
      if (value instanceof Promise) {
        value.then(setData);
      } else {
        setData(value);
      }
    }
  }, [condition]);
  return data;
}

export function useCollectionDataOnceLite<T>(
  collectionPath: string,
  components?: QueryConstraint[],
): [T[] | undefined, boolean, Error | null] {
  const [data, setData] = useState<T[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!collectionPath) {
        setLoading(false);
        return;
      }
      setLoading(true);
      const db = getFirestore(app);
      const q = query(collection(db, collectionPath), ...(components ?? []));
      try {
        const querySnapshot = await getDocs(q);
        const docsData = querySnapshot.docs.map((doc) => doc.data() as T);
        setData(docsData);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error("An unknown error occurred"));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [collectionPath]);

  return [data, loading, error];
}

export const getImpersonateToken = async (
  currentToken: string,
  uid: string,
) => {
  const result = await fetch(
    "https://us-central1-hiburim.cloudfunctions.net/impersonateUserHttp",
    {
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
      method: "POST",
      body: JSON.stringify({ uid }),
    },
  );
  if (!result.ok) {
    throw new Error("Failed to impersonate user");
  }
  return (await result.json()).token;
};
