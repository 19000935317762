"use client";

import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Input from "antd/lib/input";
import Col from "antd/lib/col";
import { Row } from "antd/lib/grid";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal";
import Typography from "antd/lib/typography";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useCoralogix } from "../../services/coralogix";
import { useAuth } from "../../services/firebase";

const ReportError = () => {
  const [isOpen, setReportErrorOpen] = useState(false);
  const closeHandler = () => setReportErrorOpen(false);
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const authenticatedEmail = auth?.user?.email;
    if (authenticatedEmail) {
      setEmail(authenticatedEmail);
      setShouldSendEmail(true);
    }
  }, [auth]);
  const Rum = useCoralogix();
  return (
    <>
      <Button
        type="link"
        onClick={() => setReportErrorOpen(true)}
        tabIndex={1}
        style={{ color: "linkColor" }}
      >
        דיווח תקלה
      </Button>
      <Modal
        title="דיווח תקלה"
        open={isOpen}
        onCancel={closeHandler}
        onOk={() => {
          Rum.critical("ERROR_REPORTED", {
            email: shouldSendEmail ? email : undefined,
            description,
            localStorage:
              typeof window != "undefined" ? { ...localStorage } : undefined,
          });
          toast("תודה על דיווח התקלה, נטפל בה בהקדם", {
            duration: 5000,
            position: "bottom-center",

            // Styling
            style: {},
            className: "",

            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#fff",
              secondary: "#000",
            },
            icon: "🙏",

            // Aria
            ariaProps: {
              role: "status",
              "aria-live": "polite",
            },
          });
          closeHandler();
        }}
        okText="דווח תקלה"
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ direction: "rtl" }}
        styles={{
          content: {
            background: "bg",
            border: "1px solid",
            boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Col
          dir="rtl"
          style={{ width: "100%", padding: 16, textAlign: "justify" }}
        >
          <Typography.Paragraph>
            אנחנו מתנצלים שחוויתם בעיה במהלך חווית המשחק. <br />
            צוות המשחק ישמח לשמוע מכם על הבעיה שחוויתם, <br />
            כדי שנוכל לפתור אותה במהירות האפשרית <br />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>פירוט הבעיה</b>
            <br />
            אנא פרטו על הבעיה בה נתקלתם: <br />
          </Typography.Paragraph>
          <TextArea
            role={"textbox"}
            aria-label={"פירוט התקלה"}
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></TextArea>
          <br />
          <br />
          <b>מייל ליצירת קשר (לא חובה)</b>
          <br />
          <Row justify="space-between" align="middle" style={{ width: "100%" }}>
            <Input
              aria-label={"מייל ליצירת קשר"}
              style={{ width: "90%" }}
              placeholder="your@email.com"
              defaultValue={email}
              disabled={!shouldSendEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Checkbox
              aria-label={"שלח לי מייל"}
              style={{ width: "5%" }}
              defaultChecked={shouldSendEmail}
              onChange={() => setShouldSendEmail(!shouldSendEmail)}
            />
          </Row>
        </Col>
      </Modal>
    </>
  );
};

export default ReportError;
