import ConfigProvider from "antd/lib/config-provider";
import { ReactNode } from "react";
import { colors } from "../styles/themes";

const InnerThemeWrapper = ({
  children,
  tokenOverrides,
}: {
  children: ReactNode;
  tokenOverrides?: Record<string, any>;
}) => {
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        hashed: false,
        components: {
          Input: {
            activeBorderColor: colors.color,
            hoverBorderColor: colors.color,
          },
          Modal: {
            contentBg: colors.bg,
            colorBorder: colors.color,
          },
          Tooltip: {
            colorText: colors.color,
          },
          Tag: {
            defaultColor: "white",
          },
          Typography: {
            fontSizeLG: 20,
            fontSizeHeading5: 13,
            fontSize: 15,
            fontFamily: "Open Sans",
          },
          Table: {
            borderColor: colors.color,
            colorBgContainer: colors.bg,
          },
          Button: {
            primaryColor: colors.wordText,
            colorPrimary: colors.wordBg,
            // colorPrimaryHover: colors.wordBgHover,
            colorPrimaryActive: colors.wordBgActive,
            // defaultHoverBg: colors.wordBgHover,
          },
          Menu: {
            groupTitleColor: colors.menuText,
            groupTitleFontSize: 14,
            groupTitleLineHeight: 1,
            colorBgContainer: colors.menu,
            colorText: colors.menuText,
          },
          Layout: {
            footerBg: colors.menu,
          },
        },
        token: {
          colorTextPlaceholder: `color-mix(in srgb, ${colors.bg}, ${colors.color})`,
          colorPrimary: colors.wordText,
          fontFamily: "Open Sans, Arial",
          lineHeight: 1.7,
          colorBorder: colors.color,
          colorSplit: colors.color,
          controlItemBgActive: colors.menuActiveText,
          colorBgContainer: colors.bg,
          colorPrimaryBg: colors.bg,
          colorBgLayout: colors.bg,
          colorText: colors.color,
          colorTextHeading: colors.color,
          colorBgElevated: colors.menu,
          colorIcon: colors.color,
          colorBgMask: `rgba(0,0,0,0.5)`,
          colorTextDisabled: `${colors.color}80`,
          colorBgBase: colors.bg,
          colorBgSpotlight: colors.wordBgActive,
          colorTextLightSolid: colors.bg,
          ...(tokenOverrides || {}),
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

const ThemeConfigProvider = ({
  children,
  tokenOverrides,
}: {
  children: ReactNode;
  tokenOverrides?: Record<string, any>;
}) => {
  return (
    <InnerThemeWrapper tokenOverrides={tokenOverrides}>
      {children}
    </InnerThemeWrapper>
  );
};

export default ThemeConfigProvider;
