"use client";

import Button from "antd/lib/button";
import { AriaRole, ReactNode } from "react";
import { colors } from "../../styles/themes";

// Assuming the icon is passed as a React component (e.g., <YourIcon />)
export const MenuAction = <
  T extends {
    icon?: JSX.Element;
    label?: string;
    children?: ReactNode;
    role?: AriaRole;
  },
>({
  icon,
  label,
  children,
  role,
  ...props
}: T) => {
  icon = <div aria-hidden={true}>{icon}</div>;
  return (
    <Button
      type="text"
      size="middle"
      icon={icon}
      aria-label={label}
      role={role}
      style={{
        color: colors.menuText,
        height: "52px",
        minWidth: "52px",
        alignContent: "center",
        alignItems: "center",
        verticalAlign: "middle",
        lineHeight: "0px", // idk why this works tbh
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
