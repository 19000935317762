"use client";

import Dropdown from "antd/lib/dropdown";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoColorPalette } from "react-icons/io5";
import { themes } from "../../styles/themes";
import { isMobile } from "../../utils/is-mobile";
import { useSetColorTheme } from "../../utils/useSetColorTheme";
import { MenuAction } from "./MenuAction";

export const ThemePicker = () => {
  const setColorSetName = useSetColorTheme();
  return (
    <Dropdown
      menu={{
        items: Object.keys(themes).map((theme) => ({
          key: theme,
          label: theme,
          onClick: () => {
            setColorSetName(theme);
          },
        })),
      }}
      trigger={["click"]}
    >
      <MenuAction label={"בחירת ערכת צבע"} role={"listbox"}>
        <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
          <IoColorPalette size="32px" />
          <div style={{ fontSize: "14px", margin: "auto", padding: "2px" }}>
            {isMobile() ? (
              <FaChevronUp size={14} />
            ) : (
              <FaChevronDown size={14} />
            )}
          </div>
        </div>
      </MenuAction>
    </Dropdown>
  );
};
