import { doc, runTransaction } from "@firebase/firestore/lite";
import { db } from "./firebase.ts";
import { getCoralogix } from "./coralogix.ts";
const Rum = getCoralogix();

export const SCORE_COLLECTION = "scores";

export type Score = {
  count: number;
  scores: number[];
  solveOrder: Record<string, number>;
};

function newScore(): Score {
  return {
    count: 0,
    scores: Array.from(Array(5).keys()).fill(0),
    solveOrder: {},
  };
}
export async function reportScore(
  dt: string,
  win: boolean,
  mistakesRemaining: number,
  completedCategories: number[],
) {
  if (mistakesRemaining < 0 || mistakesRemaining > 4) {
    return;
  }
  const score = Math.floor(win ? mistakesRemaining : 0);

  const scoresDocRef = doc(db, SCORE_COLLECTION, dt);
  try {
    await runTransaction(db, async (transaction) => {
      const scoresDoc = await transaction.get(scoresDocRef);
      const exists = scoresDoc.exists();
      const scores = exists ? (scoresDoc.data() as Score) : newScore();
      scores.count += 1;
      scores.scores[score] += 1;
      if (completedCategories.length > 0) {
        const solveOrderKey = completedCategories.join("|");
        if (!scores.solveOrder) {
          scores.solveOrder = {};
        }
        if (!(solveOrderKey in scores.solveOrder)) {
          scores.solveOrder[solveOrderKey] = 0;
        }
        scores.solveOrder[solveOrderKey] += 1;
      }
      exists
        ? transaction.update(scoresDocRef, scores)
        : transaction.set(scoresDocRef, scores);
    });
  } catch (e) {
    Rum.error("failed updating scores: ", e);
  }
}
