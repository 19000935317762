"use client";

import React from "react";

const defaultCastFunc = <T,>(val: string | T) => {
  return val as T;
};
export const useLocalStorageValue = <T extends { toString: () => string }>(
  key: string,
  initialValue: T,
  castFunc: (val: string | T) => T = defaultCastFunc,
): readonly [T, (v: T) => void] => {
  const [value, setValue] = React.useState(() => {
    if (typeof window === "undefined") return initialValue;
    const storedValue = localStorage.getItem(key);
    const value = storedValue ?? initialValue;
    return castFunc(value);
  });

  React.useEffect(() => {
    localStorage.setItem(key, value.toString());
  }, [key, value]);

  return [value, setValue];
};
