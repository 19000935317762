import Image from "next/image";
import header from "../../public/hiburim-logo.png";
import { rawSizesForNext } from "../styles/sizes";

const Header = () => {
  return (
    <div className="headerLogo">
      <Image
        fill
        priority
        sizes={rawSizesForNext.viewWidth}
        alt="חיבורים"
        src={header.src}
      />
    </div>
  );
};
export default Header;
