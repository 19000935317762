import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import { isMobile } from "../../utils/is-mobile.ts";
import HomePageButton from "./HomePageButton.tsx";
import InstructionsAction from "./InstructionsAction.tsx";
import { NavigationMenu } from "./NavigationMenu.tsx";
import { ThemePicker } from "./ThemePicker.tsx";
import { WebPushAction } from "./WebPushAction.tsx";
import "./navbar.css";
import Alert from "antd/lib/alert";
import Typography from "antd/lib/typography";
import BuyMeACoffee from "../BuyMeACoffee.tsx";
import { usePathname } from "next/navigation";

const Navbar = () => {
  const path = usePathname();
  return (
    <div>
      <Row className="navbar" dir="rtl">
        <NavigationMenu />
        {isMobile() && (
          <Divider
            type="vertical"
            style={{ borderColor: "#bdbdbd", height: "48px" }}
          />
        )}
        <HomePageButton />
        <InstructionsAction />
        <WebPushAction />
        <div style={{ flex: 1 }}></div>
        <ThemePicker />
      </Row>
      {path === "/" && (
        <Alert
          message={
            <Row justify="center">
              <Typography.Title
                level={5}
                style={{
                  marginLeft: "2vh",
                  marginTop: "1.1vh",
                  color: "black",
                }}
              >
                נהנים מחיבורים? נעריך אם תרצו לתמוך בנו
              </Typography.Title>
              <BuyMeACoffee />
            </Row>
          }
          type="warning"
        />
      )}
    </div>
  );
};

export default Navbar;
