const feminine = {
  colors: {
    menu: "#F2ECFF",
    header: "#D191A0",
    wordBg: "#F2ECFF",
    wordText: "#D191A0",
    mistakeCircle: "#00C896",
    link: "#FFA591",
    wordBgHover: "#E8DFF5",
    wordTextHover: "#B1728E",
    wordTextActive: "#FFFFFF",
    wordBgActive: "#C985A4",
    menuText: "#A0508B", // Readable over menu's color
    menuActiveText: "#A0508B30", // Readable over menu's color
    color: "#9B5FB4",
    bg: "white",
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};

const masculine = {
  colors: {
    menu: "#233237",
    header: "#455B63",
    wordBg: "#D9E4EC",
    wordText: "#233237",
    mistakeCircle: "#E94560",
    link: "#577399",
    bg: "#F0F5F9",
    color: "#5d839c",
    wordBgHover: "#CDD6DC",
    wordTextHover: "#2B3B42",
    wordTextActive: "#FFFFFF",
    wordBgActive: "#3E4F57",
    menuText: "#E3F2FD", // Readable over menu's color
    menuActiveText: "#E3F2FD70", // Readable over menu's color
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};

const oceanic = {
  colors: {
    menu: "#72BBCB",
    header: "#3A7D8E",
    wordBg: "#E0F7FA",
    wordText: "#005D6E",
    mistakeCircle: "#FF7043",
    link: "#0086B3",
    bg: "#E0F2F1",
    color: "#007C91",
    wordBgHover: "#BCE3E9",
    wordTextHover: "#007380",
    wordTextActive: "#FFFFFF",
    wordBgActive: "#5A8A94",
    menuText: "#025E73", // Readable over menu's color
    menuActiveText: "#025E7340", // Readable over menu's color
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};

const sunset = {
  colors: {
    menu: "#F2C94C",
    header: "#F2994A",
    wordBg: "#FBE9E7",
    wordText: "#C13515",
    mistakeCircle: "#EB5757",
    link: "#F27649",
    bg: "#FFEBEA",
    color: "#D84315",
    wordBgHover: "#FCD7C1",
    wordTextHover: "#E75F28",
    wordTextActive: "#FFFFFF",
    wordBgActive: "#E76D4A",
    menuText: "#963D02", // Readable over menu's color
    menuActiveText: "#963D0250", // Readable over menu's color
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};

const modest = {
  colors: {
    menu: "#E8E8E8",
    header: "#A9A9A9",
    wordBg: "#FFFFFF",
    wordText: "#333333",
    mistakeCircle: "#FF6347",
    link: "#4F4F4F",
    bg: "#F0F0F0",
    color: "#2C2C2C",
    wordBgHover: "#F5F5F5",
    wordTextHover: "#5A5A5A",
    wordTextActive: "#FFFFFF",
    wordBgActive: "#BEBEBE",
    menuText: "#575757", // Readable over menu's color
    menuActiveText: "#57575780", // Readable over menu's color
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};

const dark = {
  colors: {
    menu: "#1B2430",
    header: "white",
    wordBg: "#48494B",
    wordText: "#ffffff",
    wordBgHover: "#D6EBF2",
    wordTextHover: "black",
    mistakeCircle: "#E63946",
    link: "#4A6FA5",
    bg: "#0A1014",
    color: "#CDD6E3",
    wordBgActive: "#D0D0D0",
    wordTextActive: "black",
    menuText: "#CDD6E3", // Ensuring readability over dark menu
    menuActiveText: "#9297a6",
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};

const light = {
  colors: {
    menu: "#FAFAFA",
    header: "#black",
    wordBg: "#efefe6",
    wordText: "#333333",
    wordBgHover: "#D6EBF2",
    wordTextHover: "black",
    mistakeCircle: "#FFA07A",
    link: "#1E90FF",
    bg: "#FFFFFF",
    color: "#333333",
    wordTextActive: "white",
    wordBgActive: "#5a594e",
    menuActiveText: "#dfe1e6",
    menuText: "#0f0368", // Contrast with light menu background
  },
  styles: {
    global: {
      body: {
        bg: "bg",
        color: "color",
      },
    },
  },
};
const extendTheme = (theme: { colors: Record<string, any> }) => theme;
export const themes = {
  בהיר: extendTheme(light),
  חשוך: extendTheme(dark),
  עדין: extendTheme(feminine),
  "חביב הקהל": extendTheme(masculine),
  אוקיינוס: extendTheme(oceanic),
  שקיעה: extendTheme(sunset),
  צנוע: extendTheme(modest),
};
export const colors = Object.fromEntries(
  Object.keys(themes.בהיר.colors).map((key) => [key, `var(--hiburim-${key})`]),
);

export const getColors = (color: keyof typeof themes) => themes[color].colors;
