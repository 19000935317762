import { useCallback, useEffect, useState } from "react";
import { getOrRegisterServiceWorker } from "../services/notifications";

export enum WebPushStatus {
  UNKNOWN,
  REQUIRES_PWA_INSTALLATION,
  NOT_SUBSCRIBED,
  SUBSCIBED,
  ERROR,
}
export const useWebPushStatus = () => {
  const [status, setStatus] = useState<WebPushStatus>(WebPushStatus.UNKNOWN);
  if (typeof window === "undefined") return { status, checkStatus: () => {} };
  const isPwa = window.matchMedia("(display-mode: standalone)").matches;
  const checkStatus = useCallback(() => {
    if (/iPhone|iPad/.test(navigator.userAgent) && !isPwa)
      return setStatus(WebPushStatus.REQUIRES_PWA_INSTALLATION);
    getOrRegisterServiceWorker()
      .then(async (registration: ServiceWorkerRegistration) => {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          setStatus(WebPushStatus.SUBSCIBED);
        } else {
          setStatus(WebPushStatus.NOT_SUBSCRIBED);
        }
      })
      .catch(async () => {
        // Check if the reason for failure is due to the user denying permission
        if (
          await navigator.permissions
            .query({ name: "notifications" })
            .then((result) => result.state === "denied")
        ) {
          setStatus(WebPushStatus.ERROR);
        } else {
          setStatus(WebPushStatus.NOT_SUBSCRIBED);
        }
      });
  }, []);
  useEffect(() => {
    checkStatus();
  }, []);
  return { status, checkStatus };
};
